<template>
  <div
    class="px-4 py-4 cms-content-styled"
    :class="[style, width]"
    v-html="blockData.text"
  />
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    },
    style() {
      if (this.blockData.style === 'largeLeft') {
        return 'text-left md:text-2xl';
      }
      if (this.blockData.style === 'mediumCenter') {
        return 'text-center md:text-lg';
      }
      if (this.blockData.style === 'largeCenter') {
        return 'text-center md:text-2xl';
      }
      return 'text-left text-lg';
    }
  }
};
</script>
