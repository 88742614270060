<template>
  <div class="px-4 py-4" :class="width">
    <h3 v-if="blockData.heading" class="font-bold uppercase m-0" :class="style">
      {{ blockData.heading }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'HeadingBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    },
    style() {
      if (this.blockData.style === 'largeLeft') {
        return 'text-left text-2xl md:text-4xl';
      }
      if (this.blockData.style === 'mediumCenter') {
        return 'text-center text-2xl md:text-3xl';
      }
      if (this.blockData.style === 'largeCenter') {
        return 'text-center text-2xl md:text-4xl';
      }
      return 'text-left text-2xl';
    }
  }
};
</script>
