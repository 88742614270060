<template>
  <div>
    <div class="px-4 py-4" :class="width">
      <article v-if="images && images.length" class="relative z-0">
        <div
          class="flex items-center justify-between absolute inset-0 w-full h-full"
        >
          <CarouselBack @clicked="showPrev" />
          <CarouselFwd @clicked="showNext" />
        </div>
        <ul>
          <VueSlickCarousel v-bind="settings" ref="carousel">
            <li
              class="px-3 relative flex justify-center"
              v-for="(image, index) in images"
              :key="image.id"
            >
              <div class="gallery-cell">
                <button
                  v-if="hasModal"
                  type="button"
                  @click="imageClick(image.url, index)"
                >
                  <img
                    :src="image.url"
                    alt=""
                    class="object-center object-cover mx-auto"
                    :class="height"
                  />
                </button>
                <img
                  v-else
                  :src="image.url"
                  alt=""
                  class="object-center object-cover mx-auto"
                  :class="height"
                />
              </div>
            </li>
          </VueSlickCarousel>
        </ul>
      </article>
    </div>
    <transition name="fade">
      <ImageCarouselModal
        v-if="hasModal && modalOpen"
        :block-data="blockData"
        :clicked-image-index="clickedImageIndex"
        :clicked-image-url="clickedImageUrl"
        :is-craft="isCraft"
        @close="modalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import CarouselFwd from './CarouselFwd';
import CarouselBack from './CarouselBack';
import VueSlickCarousel from 'vue-slick-carousel';
import ImageCarouselModal from './ImageCarouselModal';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'Carousel',
  props: {
    blockData: {
      type: Object,
      required: true
    },
    withModal: {
      type: Boolean,
      default: false
    },
    slidesToShow: {
      type: Number,
      default: 4
    },
    isCraft: {
      type: Boolean,
      default: false
    },
    btnBg: {
      type: Boolean,
      default: true
    }
  },
  components: {
    VueSlickCarousel,
    CarouselBack,
    CarouselFwd,
    ImageCarouselModal
  },
  data() {
    return {
      clickedImageUrl: null,
      clickedImageIndex: null,
      modalOpen: false,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        variableWidth: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        slidesToShow: this.slidesToShow,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    };
  },
  computed: {
    height() {
      if (this.blockData.height === 'standard') {
        return 'rc-img-standard';
      }
      if (this.blockData.height === 'tall') {
        return 'rc-img-tall';
      }
      return 'rc-img-standard';
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    },
    hasModal() {
      if (this.withModal === true) return true;
      return this.blockData && this.blockData.modal;
    },
    images() {
      if (!this.blockData) return [];
      if (this.isCraft === false) {
        return this.blockData.images;
      }
      if (this.blockData.imageAspectRatio === 'landscaped') {
        return this.blockData.img1000xAuto;
      }
      if (this.blockData.imageAspectRatio === 'portrait') {
        return this.blockData.img500x1000;
      }
      if (this.blockData.imageAspectRatio === 'square') {
        return this.blockData.img500x500;
      }
      return this.blockData.imgAutox600;
    }
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    imageClick(imageUrl, index) {
      this.modalOpen = true;
      this.clickedImageUrl = imageUrl;
      this.clickedImageIndex = index;
    }
  }
};
</script>

<style scoped>
.rc-img-tall,
.rc-img-standard {
  max-height: 400px;
}
@media (min-width: 768px) {
  .rc-img-standard {
    height: 250px;
  }
  .rc-img-tall {
    height: 500px;
  }
}
</style>
