<template>
  <div class="px-4 py-4 mx-auto rc-container max-w-screen-xl">
    <div v-if="blockData.image && blockData.image.length">
      <router-link
        v-if="blockData.imageUrl"
        :to="localizedRoute(blockData.imageUrl)"
      >
        <template>
          <img
            v-if="
              blockData.image &&
              blockData.image.length &&
              !blockData.imageMobile.length
            "
            :src="blockData.image[0].url"
            alt=""
            class="md:hidden"
            :class="width"
          />
          <img
            v-if="blockData.image && blockData.image.length"
            :src="blockData.image[0].url"
            alt=""
            class="rc-hidden"
            :class="width"
          />
          <img
            v-if="blockData.imageMobile && blockData.imageMobile.length"
            :src="blockData.imageMobile[0].url"
            alt=""
            class="md:hidden"
            :class="width"
          />
        </template>
      </router-link>
      <template v-else>
        <img
          v-if="
            blockData.image &&
            blockData.image.length &&
            !blockData.imageMobile.length
          "
          :src="blockData.image[0].url"
          alt=""
          class="md:hidden"
          :class="width"
        />
        <img
          v-if="blockData.image && blockData.image.length"
          :src="blockData.image[0].url"
          alt=""
          class="rc-hidden"
          :class="width"
        />
        <img
          v-if="blockData.imageMobile && blockData.imageMobile.length"
          :src="blockData.imageMobile[0].url"
          alt=""
          class="md:hidden"
          :class="width"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'ImageBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto w-full';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto w-full';
      }
      return 'mx-auto w-full';
    }
  }
};
</script>

<style scoped>
.rc-hidden {
  display: none;
}
@media (min-width: 768px) {
  .rc-hidden {
    display: block;
  }
}
</style>
