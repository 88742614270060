<template>
  <transition name="fade">
    <div class="fixed w-screen h-screen left-0 top-0 z-50 bg-black bg-opacity-75 flex justify-center items-center transition p-5" @click="close">
      <article class="relative" @click.stop>
        <button
          type="button"
          class="absolute -top-4 -right-4 z-10 text-blue-500 bg-black w-10 h-10 bg-opacity-70 rounded-full flex items-center justify-center"
          @click="close"
        >
          <Cross class="h-4 w-4" />
        </button>
        <img :src="clickedImageUrl" alt="" />
      </article>
    </div>
  </transition>
</template>

<script>
import Cross from 'theme/components/theme/bikebuilder/svg/ui/Cross';

export default {
  name: 'ImageModal',
  props: {
    clickedImageUrl: {
      type: String,
      default: null
    },
    blockData: {
      type: Object,
      required: true
    }
  },
  components: {
    Cross
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
