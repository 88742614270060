<template>
  <transition name="fade">
    <div class="rc-modal-container fixed w-screen h-screen left-0 top-0 z-50 bg-black bg-opacity-75 flex justify-center items-center transition p-5" @click="close">
      <article class="relative rc-modal" @click.stop :class="modalWidth">
        <button
          type="button"
          class="absolute -top-4 -right-2 z-10 text-blue-500 bg-black w-10 h-10 bg-opacity-70 rounded-full flex items-center justify-center md:-right-4"
          @click="close"
        >
          <Cross class="h-4 w-4" />
        </button>
        <CarouselForModal :block-data="blockData" :clicked-image-index="clickedImageIndex" :modal-width="modalWidth" :is-craft="isCraft" />
      </article>
    </div>
  </transition>
</template>

<script>
import Cross from 'theme/components/theme/bikebuilder/svg/ui/Cross';
import CarouselForModal from 'theme/components/theme/bikebuilder/external/craftcms/CarouselForModal';

export default {
  name: 'ImageModal',
  props: {
    clickedImageIndex: {
      type: Number,
      default: null
    },
    clickedImageUrl: {
      type: String,
      default: null
    },
    blockData: {
      type: Object,
      required: true
    },
    isCraft: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Cross,
    CarouselForModal
  },
  computed: {
    modalWidth () {
      if (this.blockData.imageAspectRatio === 'landscape') {
        return 'rc-modal__landscape';
      }
      if (this.blockData.imageAspectRatio === 'portrait') {
        return 'rc-modal__landscape';
      }
      if (this.blockData.imageAspectRatio === 'square') {
        return 'rc-modal__landscape';
      }
      return 'rc-modal__landscape';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.rc-modal {
  width: 90vw;
  max-height: 90vh;
}
.rc-modal-container {
  z-index: 1000000;
}
@media (min-width: 1280px) {
  .rc-modal__landscape {
    width: 1000px;
  }
  .rc-modal__portrait {
    width: 500px;
  }
  .rc-modal__square {
    width: 500px;
  }
  .rc-modal__standard {
    width: 700px;
  }
}
</style>
