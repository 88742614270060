<template>
  <div :class="width">
    <router-link v-if="blockData.ctaUrl" :to="localizedRoute(blockData.ctaUrl)">
      <HeroInner :blockData="blockData" :width="width" />
    </router-link>
    <HeroInner v-else :blockData="blockData" :width="width" />
  </div>
</template>

<script>
import HeroInner from './HeroInner';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'Hero',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  components: { HeroInner },
  computed: {
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>

<style scoped>
a:hover,
a:focus {
  color: white !important; /* ayko style overide */
}
</style>
