<template>
  <div class="px-4 py-4" :class="width">
    <div
      class="rc-bg bg-cover grid grid-cols-1 lg:grid-cols-3 md:p-16 p-3 content-center w-full"
      :style="imageStyles"
    >
      <div class="text-white" :class="textPosition">
        <h4 v-if="blockData.heading" class="m-0 mb-2 text-2xl font-bold">
          {{ blockData.heading }}
        </h4>
        <div
          v-if="blockData.text"
          v-html="blockData.text"
          class="cms-content-styled"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageLeftTextRight',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    bgImage() {
      if (!this.blockData || !this.blockData.image.length) return null;
      return this.blockData.image[0].url;
    },
    textPosition() {
      if (this.blockData.textPosition === 'center') {
        return 'col-start-2 col-end-3 text-center';
      }
      if (this.blockData.textPosition === 'right') {
        return 'col-start-3 col-end-4';
      }
      return 'col-start-1 col-end-2';
    },
    imageStyles() {
      return `background-image:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${this.bgImage})`;
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>

<style scoped>
.rc-bg {
  min-height: 800px;
}

@media (max-width: 1025px) {
  .rc-bg {
    min-height: 400px;
  }
}
</style>
