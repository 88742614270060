var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-4 mx-auto rc-container max-w-screen-xl"},[(_vm.blockData.image && _vm.blockData.image.length)?_c('div',[(_vm.blockData.imageUrl)?_c('router-link',{attrs:{"to":_vm.localizedRoute(_vm.blockData.imageUrl)}},[[(
            _vm.blockData.image &&
            _vm.blockData.image.length &&
            !_vm.blockData.imageMobile.length
          )?_c('img',{staticClass:"md:hidden",class:_vm.width,attrs:{"src":_vm.blockData.image[0].url,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.blockData.image && _vm.blockData.image.length)?_c('img',{staticClass:"rc-hidden",class:_vm.width,attrs:{"src":_vm.blockData.image[0].url,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.blockData.imageMobile && _vm.blockData.imageMobile.length)?_c('img',{staticClass:"md:hidden",class:_vm.width,attrs:{"src":_vm.blockData.imageMobile[0].url,"alt":""}}):_vm._e()]],2):[(
          _vm.blockData.image &&
          _vm.blockData.image.length &&
          !_vm.blockData.imageMobile.length
        )?_c('img',{staticClass:"md:hidden",class:_vm.width,attrs:{"src":_vm.blockData.image[0].url,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.blockData.image && _vm.blockData.image.length)?_c('img',{staticClass:"rc-hidden",class:_vm.width,attrs:{"src":_vm.blockData.image[0].url,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.blockData.imageMobile && _vm.blockData.imageMobile.length)?_c('img',{staticClass:"md:hidden",class:_vm.width,attrs:{"src":_vm.blockData.imageMobile[0].url,"alt":""}}):_vm._e()]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }