<template>
  <div class="px-4 py-4 mx-auto rc-container max-w-screen-xl">
    <video
      v-if="video"
      class="object-cover h-full w-full"
      :controls="hasControls"
      :autoplay="autoplay"
      :loop="autoplay"
      :muted="autoplay"
      :poster="fallbackImage"
      playsinline
    >
      <source :src="video" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoBlock',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    fallbackImage () {
      if (!this.blockData || !this.blockData.fallbackImage.length) return null;
      return this.blockData.fallbackImage[0].url;
    },
    video () {
      if (!this.blockData || (!this.videoAsset && !this.vimeo)) return null;
      return this.blockData.useVimeo ? this.vimeo : this.videoAsset;
    },
    videoAsset () {
      if (!this.blockData || !this.blockData.video.length) return null;
      return this.blockData.video[0].url;
    },
    vimeo () {
      if (!this.blockData) return null;
      return this.blockData.vimeoUrl;
    },
    autoplay () {
      if (!this.blockData) return false;
      return this.blockData.autoplay;
    },
    hasControls () {
      if (!this.blockData) return false;
      return this.blockData.hasControls;
    }
  }
};
</script>
