<template>
  <button @click="showNext" role="button" aria-label="slide forward" :class="btnStyles">
    <Back class="h-8 w-8 transform rotate-180" />
  </button>
</template>

<script>
import Back from '../../svg/ui/Back';

export default {
  name: 'CarouselFwd',
  components: { Back },
  computed: {
    btnStyles () {
      const baseStyles = 'z-10 border-none px-2 py-8 text-blue-500'
      return `${baseStyles} -mr-5`;
    }
  },
  methods: {
    showNext () {
      this.$emit('clicked');
    }
  },
};
</script>
