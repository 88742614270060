<template>
  <div class="px-4 py-4 mx-auto rc-container max-w-screen-xl">
    <figure class="max-w-lg mx-auto relative p-5 md:p-0">
      <svg
        viewBox="0 0 50 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 md:h-8 absolute -top-5 left-0 md:top-0 md:-ml-20 fill-current"
      >
        <path
          d="M21.4456 7.34253V0C17.0266 0.783992 13.0473 2.24758 9.50801 4.39129C5.94892 6.55078 3.50921 9.15014 2.18965 12.1897C0.729797 15.3089 0 19.2601 0 24.0434V33.3055H21.4457V13.1975H16.077C16.4569 10.7501 18.2465 8.79853 21.4457 7.3427M50 7.3427V0.0001713C45.5809 0.784163 41.6117 2.24775 38.0924 4.39146C34.6531 6.55095 32.2037 9.15031 30.7439 12.1899C29.2843 15.3091 28.5542 19.2603 28.5542 24.0435V33.3057H49.9999V13.1977H44.6612C45.1811 10.7503 46.9607 8.7987 49.9999 7.34287L50 7.3427Z"
        />
      </svg>
      <blockquote class="italic whitespace-pre-line m-0">
        {{ blockData.text }}
      </blockquote>
      <figcaption class="text-right">
        {{ blockData.quotedBy }}
      </figcaption>
      <svg
        viewBox="0 0 51 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 md:h-8 absolute -bottom-5 right-0 md:-mt-12 md:-mr-20 fill-current"
      >
        <path
          d="M21.8994 9.49528V0.238733H0.466644V20.3106H5.77229C5.29254 22.7568 3.5241 24.659 0.466644 26.0181V33.5241C4.84295 32.7249 8.81972 31.2538 12.397 29.1117C15.7744 26.9692 18.2125 24.3794 19.7113 21.3417C21.1699 18.2082 21.8996 14.2596 21.8996 9.49519M50.4666 9.49519V0.238647H29.0339V20.3105H34.3098C33.9301 22.7567 32.1717 24.659 29.0339 26.018V33.524C33.3904 32.7248 37.3573 31.2537 40.9343 29.1116C44.4712 26.9691 46.9195 24.3793 48.2784 21.3416C49.737 18.2082 50.4664 14.2595 50.4664 9.49511L50.4666 9.49519Z"
        />
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Quote',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  }
};
</script>
