<template>
  <button @click="showPrev" role="button" aria-label="slide back" :class="btnStyles">
    <Back class="h-8 w-8" />
  </button>
</template>

<script>
import Back from '../../svg/ui/Back';

export default {
    name: "CarouselBack",
    components: { Back },
    computed: {
      btnStyles() {
          const baseStyles = "z-10 border-none px-2 py-8 text-blue-500";
          return `${baseStyles} -ml-5`;
      }
    },
    methods: {
        showPrev() {
            this.$emit("clicked");
        }
    }
};
</script>
