<template>
  <article v-if="images && images.length" class="relative">
    <div class="flex items-center justify-between absolute inset-0">
      <CarouselBack @clicked="showPrev" />
      <CarouselFwd @clicked="showNext" />
    </div>
    <ul>
      <VueSlickCarousel v-bind="settings" ref="carousel">
        <li v-for="image in images" :key="image.id" class="relative"
        >
          <div class="gallery-cell" >
            <img :src="image.url" alt="" class="rc-modal object-contain" :class="modalWidth" />
          </div>
        </li>
      </VueSlickCarousel>
    </ul>
  </article>
</template>

<script>
import ImageModal from './ImageModal';
import CarouselFwd from './CarouselFwd';
import CarouselBack from './CarouselBack';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'CarouselForModal',
  components: { VueSlickCarousel, CarouselBack, CarouselFwd, ImageModal },
  props: {
    blockData: {
      type: Object,
      required: true
    },
    clickedImageIndex: {
      type: Number,
      required: true
    },
    modalWidth: {
      type: String,
      required: true
    },
    isCraft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      settings: {
        initialSlide: this.clickedImageIndex,
        centerMode: true,
        focusOnSelect: false,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        variableWidth: true,
        adaptiveHeight: true,
        speed: 500
      }
    };
  },
  computed: {
    images () {
      if (!this.blockData) return [];
      if (this.isCraft === false) {
        return this.blockData.images;
      }
      if (this.blockData.imageAspectRatio === 'landscaped') {
        return this.blockData.img1000xAuto;
      }
      if (this.blockData.imageAspectRatio === 'portrait') {
        return this.blockData.img500x1000;
      }
      if (this.blockData.imageAspectRatio === 'square') {
        return this.blockData.img500x500;
      }
      return this.blockData.imgAutox600;
    }
  },
  methods: {
    showNext () {
      this.$refs.carousel.next();
    },
    showPrev () {
      this.$refs.carousel.prev();
    }
  }
};
</script>

<style scoped>
.rc-modal {
  width: 90vw;
  max-height: 90vh;
}
@media (min-width: 1280px) {
  .rc-modal__landscape {
    width: 1000px;
  }
  .rc-modal__portrait {
    width: 500px;
  }
  .rc-modal__square {
    width: 500px;
  }
  .rc-modal__standard {
    width: 700px;
  }
}
</style>
