<template>
  <div class="px-4 py-4" :class="width">
    <ul class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <li class="flex flex-col justify-between space-y-5">
        <img
          v-show="imageTopLeft"
          :src="imageTopLeft"
          alt=""
          class="object-cover"
        />
        <img
          v-show="imageBottomLeft"
          :src="imageBottomLeft"
          alt=""
          class="object-cover"
        />
      </li>
      <li class="flex flex-col justify-between space-y-5">
        <img
          v-show="imageTopRight"
          :src="imageTopRight"
          alt=""
          class="object-cover"
        />
        <img
          v-show="imageBottomRight"
          :src="imageBottomRight"
          alt=""
          class="object-cover"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ImageGrid',
  props: {
    blockData: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageTopLeft() {
      if (!this.blockData || !this.blockData.imagesLeft.length) return null;
      return this.blockData.imagesLeft[0].url;
    },
    imageBottomLeft() {
      if (!this.blockData || !this.blockData.imagesLeft.length) return null;
      if (this.blockData.imagesLeft.length === 1) return null;
      return this.blockData.imagesLeft[1].url;
    },
    imageTopRight() {
      if (!this.blockData || !this.blockData.imagesRight.length) return null;
      return this.blockData.imagesRight[0].url;
    },
    imageBottomRight() {
      if (!this.blockData || !this.blockData.imagesRight.length) return null;
      if (this.blockData.imagesRight.length === 1) return null;
      return this.blockData.imagesRight[1].url;
    },
    width() {
      if (this.blockData.width === 'standard') {
        return 'mx-auto rc-container max-w-screen-xl';
      }
      if (this.blockData.width === 'wide') {
        return 'mx-auto rc-container max-w-screen-3xl';
      }
      return null;
    }
  }
};
</script>
